.body {
  background-color: var(--colors-background-inner);
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.default {
  width: 100%;
  padding: 1.5em;
  font-size: 0.9em;
  line-height: 1.7;
  color: var(--colors-text);
}

.container {
  display: block;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

@media (max-width: 768px) {
  .default {
    font-size: 0.925em;
    line-height: 1.85;
  }
}
